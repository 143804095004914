import React from 'react';
import { Link } from 'react-router-dom';
import './UserMenu.css'; 

const UserMenu = ({ userHealthData, handleFeatureClick }) => {
    return (
        <div className="user-menu">
            
            <div className="user-menu-button-section">
                <Link to={{
                    pathname: "/health-profile",
                    state: { healthBehaviorsData: userHealthData.protectionBasedRecommendations } // passing health behaviors data
                }}>
                    <img className="user-menu-logo" src="/images/survey.png" alt="survey logo" />
                </Link>
                <img
                    onClick={() => handleFeatureClick('insurance')}
                    className="user-menu-logo"
                    src="/images/insurance-user-menu.png"
                    alt="insurance logo"
                />

                <img
                    onClick={() => handleFeatureClick('appointment')}
                    className="user-menu-logo"
                    src="/images/appointment.png"
                    alt="appointment logo"
                />
                <img
                    onClick={() => handleFeatureClick('ai')}
                    className="user-menu-logo"
                    src="/images/ai.png"
                    alt="ai logo"
                />
            </div>
        </div>
    );
}

export default UserMenu;
