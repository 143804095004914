import React from 'react';
import './OurServices.css'; 

const OurServices = () => {
  return (
    <div className="our-services-container">
      <div className="our-services">
        <h1>Our Services</h1>
        <p>Your trusted companion on the path to health and wellbeing</p>
        <p>Get expert guidance and support to make informed decisions about your health and well being</p>
        <p>Discover personalized insights and recommendations for a healthier lifesyle</p>
      </div>

      <div className="our-services-section">
        <div className="our-services-content">
          <img src="/images/dashboard-ourservices.png" alt="healthdashboard" />
          <div className="our-services-text">
              <h2>Personalized Health Dashboard</h2>
              <ul>
              <li>Tailored insights based on your unique health profile, including recommended screenings, vaccinations and health behaviours</li>
              <li>Set and achieve health goals with active progress tracking</li>
              <li>Sync reminders to your personal device for regular screening and health promotions</li>
              <li>Easily schedule appointments with providers through our AI scheduling assistant</li>
              <li>Seemlessly integrate wearable devices to monitor fitness progress</li>
              </ul>
          </div>
        </div>
      </div>
      
      <div className="our-services-section">
          <div className="our-services-content">
              <div className="our-services-text">
                  <h2>Mindfulness and Nutrition Hub</h2>
                  <ul>
                  <li>Enhance your wellbeing with access to mindfulness and nutrition resources, classes and partners</li>
                  <li>Stay informed about the latest trands and practices in health and wellness</li>
                  </ul>
              </div>
              <img src="/images/mindfulness.png" alt="mindfulness" />
        </div>
    </div>

    <div className="our-services-section">
        <div className="our-services-content">
          <img src="/images/insurance-ourservices.png" alt="insurance" />
          <div className="our-services-text">
              <h2>Insurance and Coverage Assistance</h2>
              <ul>
              <li>Understand your health insurance coverage and entitlements with ease</li>
              <li>Establish the best insurance plan for you and your family</li>
              </ul>
          </div>
        </div>
      </div>

      <div className="our-services-section">
          <div className="our-services-content">
              <div className="our-services-text">
                  <h2>Expert Health Search Engine</h2>
                  <ul>
                  <li>Have health-related questions? Ask our boutique health search engine for personalized and reliable information</li>
                  </ul>
              </div>
              <img src="/images/search-engine.png" alt="search-engine" />
        </div>
    </div>

    <div className="our-services-section">
        <div className="our-services-content">
          <img src="/images/community.png" alt="community" />
          <div className="our-services-text">
              <h2>Join our thriving community</h2>
              <ul>
              <li>Connect with like-minded individuals and track and share fitness progress together</li>
              <li>Earn rewards by referring others and participating in challenges</li>
              <li>Engage in peer support networks centered around communities of interest</li>
              </ul>
          </div>
        </div>
      </div>

    
    </div>
  );
};

export default OurServices;
