import React, { useState, useEffect, useRef } from 'react';
//import useFormSubmit from '../hooks/useFormSubmit';
import useUpdateHealthData from '../../hooks/useUpdateHealthData';
import DropDown from '../DropDown/DropDown';
import NumberInput from '../NumberInput/NumberInput';
import DateInput from '../DateInput/DateInput';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import './HealthProfileForm.css';  // Import the CSS file here
import DiseaseHistoryCheckbox from'../DiseaseHistoryCheckbox/DiseaseHistoryCheckbox';
import HealthInterestCheckbox from'../HealthInterestCheckbox/HealthInterestCheckbox';
//import DrugUseCheckbox from'../DrugUseCheckbox/DrugUseCheckbox';
import RiskyBehaviourCheckbox from'../RiskyBehaviourCheckbox/RiskyBehaviourCheckbox';
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';
//import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import GooglePlacesInput from '../GooglePlacesInput/GooglePlacesInput';
import {diseases, riskyBehaviours, riskyBehaviourMapping, healthInterests ,healthInterestMapping} from '../constants.js'





const HealthProfileForm = () => {
  const sexRef = useRef(null);
  const genderRef = useRef(null);
  const dobRef = useRef(null);
  const heightRef = useRef(null);
  const weightRef = useRef(null);
  const familyHistoryRef = useRef(null);
  const personalHistoryRef = useRef(null);
  const pregnantRef = useRef(null);
  const smokingRef = useRef(null);
  //const drugRef = useRef(null);
  const drinkingRef = useRef(null);
  const dietRef = useRef(null);
  //const sexualOrientationRef = useRef(null);
  const riskyBehaviourRef = useRef(null);
  const activityRef = useRef(null);
  const interestRef = useRef(null);
  const [heightUnit, setHeightUnit] = useState("cms");
  const [weightUnit, setWeightUnit] = useState("kgs");
  const { userHealthData, isLoading } = useContext(UserContext);
  const [selectedFamilyDiseases, setSelectedFamilyDiseases] = useState([]);
  const [selectedPersonalDiseases, setSelectedPersonalDiseases] = useState([]);
  const [selectedRiskyBehaviours, setSelectedRiskyBehaviours] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  //const [selectedDrugs, setSelectedDrugs] = useState([]);
  //const [smokingDetails, setSmokingDetails] = useState('');
  const navigate = useNavigate();
  const onSuccess = () => navigate('/dashboard');

  

  const initialState = userHealthData?.personalData
    ? {
        ...userHealthData.personalData,
        weightUnit: userHealthData.personalData.weightUnit || 'kgs',
        heightUnit: userHealthData.personalData.heightUnit || 'cms',
        address: userHealthData.personalData.address || '',
        dob: userHealthData.personalData.dob
          ? dayjs(userHealthData.personalData.dob)
          : '', // convert date string to dayjs object
      }
    : {
        
        //... other fields
      };

  console.log(initialState)
  
  const [formData, setFormData, handleSubmit] = useUpdateHealthData(initialState, onSuccess);

  useEffect(() => {
    setHeightUnit(formData.heightUnit);
    setWeightUnit(formData.weightUnit);
  }, [formData.heightUnit, formData.weightUnit]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (userHealthData && userHealthData.personalData) {
      let data = { ...userHealthData.personalData };
  
      if (userHealthData.personalData.sex){

        data.sex = data.sex.charAt(0).toUpperCase() + data.sex.slice(1);
      }
      
      
      data.pregnant = data.pregnant ? 'Yes' : 'No'; 
      data.smoke = data.smoke ? 'Current smoker' : 'Never a smoker'; 
        
      if (data.dob) {
        data.dob = dayjs(data.dob);
      }

  
      const initialSelectedFamilyHistoryDiseases = diseases.filter(disease => 
        data[`familyHistoryOf${disease.replace(' ', '')}`]
      );
      const initialSelectedPersonalHistoryDiseases = diseases.filter(disease => 
        data[`personalHistoryOf${disease.replace(' ', '')}`]
      );
      setSelectedFamilyDiseases(initialSelectedFamilyHistoryDiseases);
      setSelectedPersonalDiseases(initialSelectedPersonalHistoryDiseases);

      const initialSelectedRiskyBehaviours = riskyBehaviours.filter(riskyBehaviour => {
        // Retrieve the corresponding key for the current risky behaviour from the mapping
        const correspondingRiskyBehaviourKey = Object.keys(riskyBehaviourMapping).find(key => riskyBehaviourMapping[key] === riskyBehaviour);
    
        // Check if a truthy value exists for the retrieved key in the data object
        return data.riskyBehaviours[correspondingRiskyBehaviourKey];
    });

      console.log(initialSelectedRiskyBehaviours)
    
      setSelectedRiskyBehaviours(initialSelectedRiskyBehaviours);

      const initialSelectedHealthInterests = healthInterests.filter(healthInterest => {
        const correspondingHealthInterestKey = Object.keys(healthInterestMapping).find(key => healthInterestMapping[key] === healthInterest)
        console.log(data.interests)
        return data.interests[correspondingHealthInterestKey]
      })

      console.log(initialSelectedHealthInterests)
      setSelectedInterests(initialSelectedHealthInterests)

    

  
      setFormData(data);
    
    }
  }, [userHealthData, setFormData]);

  const toggleHeightUnit = () => {
    if (heightUnit === 'cms') {
      setHeightUnit('ft/inches');
      setFormData(prevData => ({ ...prevData, heightUnit: 'ft/inches' }));
    } else {
      setHeightUnit('cms');
      setFormData(prevData => ({ ...prevData, heightUnit: 'cms' }));
    }
  };

  const toggleWeightUnit = () => {
    if (weightUnit === 'kgs') {
      setWeightUnit('lbs');
      setFormData(prevData => ({ ...prevData, weightUnit: 'lbs' }));
    } else {
      setWeightUnit('kgs');
      setFormData(prevData => ({ ...prevData, weightUnit: 'kgs' }));
    }
  };
  
  
  const handleAddressChange = (address) => {
    // update your form state with the address
    console.log("Address changed:", address);
    setFormData((prevState) => ({ ...prevState, address }));
  };
  

  const handleChange = (value, fieldName) => {
    console.log('handleChange called with value:', value, 'and fieldName:', fieldName); // Debugging line
  
    if (fieldName === 'drugUseStatus' && value === 'Never used drugs') {
      setFormData(prevData => ({
        ...prevData,
        drugUseStatus: value,
        drugs: 'N/A',
        drugFrequency: 'N/A'
      }));
    } else if (fieldName === 'heightCms') {
      const inches = parseFloat(value) / 2.54;
      const feet = Math.floor(inches / 12);
      const remainingInches = inches % 12;
  
      setFormData(prevData => ({
        ...prevData,
        heightCms: value,
        heightFt: feet,
        heightInches: remainingInches
      }));
  
    } else if (fieldName === 'heightFt' || fieldName === 'heightInches') {
      const totalInches = (parseFloat(formData.heightFt || 0) * 12) + parseFloat(formData.heightInches || 0);
      const cms = totalInches * 2.54;
  
      setFormData(prevData => ({
        ...prevData,
        heightCms: cms,
        [fieldName]: value
      }));
      
    } else if (fieldName === 'weightKgs') {
      const lbs = parseFloat(value) * 2.20462;
      
      setFormData(prevData => ({
        ...prevData,
        weightKgs: value,
        weightLbs: lbs
      }));
  
    } else if (fieldName === 'weightLbs') {
      const kgs = parseFloat(value) / 2.20462;
  
      setFormData(prevData => ({
        ...prevData,
        weightKgs: kgs,
        weightLbs: value
      }));
  
    }
     else {
      setFormData(prevData => ({ ...prevData, [fieldName]: value }));
    }
  };

  const handleGooglePlacesSelect = (selectedPlace) => {
    const { formattedAddress, lat, lng } = selectedPlace;
    console.log("Selected Place:", selectedPlace);
  
    handleAddressChange(formattedAddress);
  
    // You can also set lat and lng in the form data if needed
    setFormData((prevState) => ({
      ...prevState,
      lat: lat,
      lng: lng,
    }));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="input-container">
      <div className="title">Enter Your Health Information</div>
      <div className="subtitle">
        With this information we can personalize your dashboard and provide you with the right recommendations to keep you healthy
      </div>

      <form onSubmit={(e) => handleSubmit(e, onSuccess)} onKeyDown={(event) => {
        if (event.key === 'Enter') event.preventDefault();
      }}>

        <div>
        
        <div className="input-group">
          <DropDown
            ref={sexRef}
            label="Sex at Birth"
            options={['Male', 'Female', 'Intersex']}
            selected={formData.sex}
            setSelected={(value) => handleChange(value, 'sex')}
            nextInputRef={genderRef}

          />
        </div>

        <div className="input-group">
          <DropDown
            ref={genderRef}
            label="Gender"
            options={['Male', 'Female','Trans Female', 'Trans Male', 'Genderqueer', 'Non-binary' ]}
            selected={formData.gender}
            setSelected={(value) => handleChange(value, 'gender')}
            nextInputRef={dobRef}

          />
        </div>
;


      <div className="input-group">
      <DateInput
        ref={dobRef}
        label="Date of Birth"
        selectedDate={formData.dob ? dayjs(formData.dob) : null} // check before converting
        setValue={(date) => handleChange(date, 'dob')}
        nextInputRef={heightRef}

      />
      </div>

      <div className="input-group">
        <GooglePlacesInput
          label="Address"
          value= {formData.address}
          onSelect={handleGooglePlacesSelect}

        />

      </div>



      <div className="input-group">
          <label className="label-flex">
            Height (
            <span 
                onClick={toggleHeightUnit} 
                className={`toggle-unit ${heightUnit === 'cms' ? 'toggle-unit-active' : ''}`}>
                cms
            </span>
            /
            <span 
                onClick={toggleHeightUnit} 
                className={`toggle-unit ${heightUnit === 'ft/inches' ? 'toggle-unit-active' : ''}`}>
                ft & inches
            </span>
            )
          </label>
          {heightUnit === 'cms' ? (
              <NumberInput
                  placeholder= "Height (cms)"
                  ref={heightRef}
                  value={formData.heightCms}
                  setValue={(value) => handleChange(value, 'heightCms')}
                  nextInputRef={weightRef}
              />
          ) : (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <NumberInput
                      className = "dual-number-input" 
                      placeholder= "Height (feet)"
                      value={formData.heightFt}
                      setValue={(value) => handleChange(value, 'heightFt')}
                  />
                  <NumberInput 
                      className = "dual-number-input" 
                      placeholder= "Height (inches)"
                      value={formData.heightInches}
                      setValue={(value) => handleChange(value, 'heightInches')}
                  />
              </div>
          )}
      </div>

      <div className="input-group">
          <label className="label-flex">
            Weight (
            <span 
                onClick={toggleWeightUnit} 
                className={`toggle-unit ${weightUnit === 'kgs' ? 'toggle-unit-active' : ''}`}>
                kgs
            </span>
            /
            <span 
                onClick={toggleWeightUnit} 
                className={`toggle-unit ${weightUnit === 'lbs' ? 'toggle-unit-active' : ''}`}>
                lbs
            </span>
            )
          </label>
          {weightUnit === 'kgs' ? (
              <NumberInput
                  placeholder= "Weight (kgs)"
                  ref={weightRef}
                  value={formData.weightKgs}
                  setValue={(value) => handleChange(value, 'weightKgs')}
                  nextInputRef={weightRef}
              />
          ) : (
              <NumberInput
              placeholder= "Weight (lbs)"
              ref={weightRef}
              value={formData.weightLbs}
              setValue={(value) => handleChange(value, 'weightLbs')}
              nextInputRef={weightRef}
            />
          )}
      </div>


      <div className="input-group">   
        <DiseaseHistoryCheckbox 
          label="Family History of Diseases:"
          sex={formData.sex}
          ref={familyHistoryRef}
          selectedDiseases={selectedFamilyDiseases}
          setSelectedDiseases={setSelectedFamilyDiseases}
          nextInputRef={personalHistoryRef}
          onChange={(newDiseases) => {
            // Map each disease to a form field and set its value based on whether it is selected
            const diseaseFields = diseases.reduce((obj, disease) => {
              return {
                ...obj,
                [`familyHistoryOf${disease.replace(' ', '')}`]: newDiseases.includes(disease)
              };
            }, {});
          
            setFormData(prevData => ({ ...prevData, ...diseaseFields }));
          }}

        />
      </div>

      <div className="input-group">   
        <DiseaseHistoryCheckbox 
          label="Personal History of Diseases:"
          ref={personalHistoryRef}
          sex={formData.sex}
          selectedDiseases={selectedPersonalDiseases}
          setSelectedDiseases={setSelectedPersonalDiseases}
          nextInputRef={pregnantRef}
          onChange={(newDiseases) => {  // Add this prop
          // Map each disease to a form field and set its value to true
          const diseaseFields = newDiseases.reduce((obj, disease) => ({
            ...obj,
            [`personalHistoryOf${disease.replace(' ', '')}`]: newDiseases.includes(disease)
          }), {});
          setFormData(prevData => ({ ...prevData, ...diseaseFields }));
        }}

        />
      </div>


      {formData.sex && formData.sex.toLowerCase() === 'female' && (

      <div className="input-group">
        <DropDown
          ref={pregnantRef}
          label="Pregnant"
          options={['Yes', 'No']}
          selected={formData.pregnant}
          setSelected={(value) => handleChange(value, 'pregnant')}
          nextInputRef={smokingRef}
        />
      </div>
      )}

      <div className="input-group">
        <DropDown
          ref={smokingRef}
          label="Smoking Status"
          options={['Current smoker', 'Previously a smoker', "Never a smoker"]}
          selected={formData.smoke}
          setSelected={(value) => handleChange(value, 'smoke')}
          nextInputRef={drinkingRef}
        />
      </div>

      {formData.smoke === 'Current smoker' && (
        <div className="input-group">
          <NumberInput
            label="How many cigarettes do you smoke a day?"
            value={formData.smokingDetails}
            setValue={(value) => handleChange(value, 'smokingDetails')}
          />
        </div>
      )}

      {formData.smoke === 'Previously a smoker' && (
        <div className="input-group">
          <NumberInput
            label="How many years since you quit smoking?"
            value={formData.smokingDetails}
            setValue={(value) => handleChange(value, 'smokingDetails')}
          />
        </div>
      )}

{/* 
      <div className="input-group">
        <DropDown
          ref={drugRef}
          label="Drug Use Status - Please note your data is private and secure"
          options={['Currently using drugs', 'Previously used drugs', "Never used drugs"]}
          selected={formData.drugUseStatus}
          setSelected={(value) => handleChange(value, 'drugUseStatus')}
          nextInputRef={drinkingRef}
        />
      </div>

      {
      (formData.drugUseStatus === 'Currently using drugs' || formData.drugUseStatus === 'Previously used drugs') && (
        <div className="input-group">
          <DrugUseCheckbox 
            ref={drugRef}
            selectedDrugs={selectedDrugs}
            setSelectedDrugs={setSelectedDrugs}
            nextInputRef={drinkingRef}
            onChange={(drugsObj) => {
              setFormData(prevData => ({ ...prevData, drugs: drugsObj }));
            }}
          />
        </div>     
      )
    }

    {
      (formData.drugUseStatus === 'Currently using drugs' || formData.drugUseStatus === 'Previously used drugs') && (
        <div className="input-group">
          <DropDown
            label="Frequency of drug use"
            options={['Daily', 'Weekly', "Monthly", "Less than monthly" ]}
            selected={formData.drugUseFrequency}
            setSelected={(value) => handleChange(value, 'drugUseFrequency')}
            nextInputRef={drinkingRef}
          />
        </div> 
      )
    }
  */} 

        <div className="input-group">
          <DropDown
            ref={drinkingRef}
            label="Drinking habits"
            options={['1 drink a month or less', '1 drink a week', '2-3 drinks a week', '4 drinks or more a week']}
            selected={formData.drinkingHabits}
            setSelected={(value) => handleChange(value, 'drinkingHabits')}
            nextInputRef={dietRef}
          />
        </div>        

        <div className="input-group">        
          <DropDown
            ref={dietRef}
            label="Dietary Habits"
            options={['Unhealthy', 'Standard', 'Healthy']}
            selected={formData.dietaryHabits}
            setSelected={(value) => handleChange(value, 'dietaryHabits')}
            nextInputRef={activityRef}
          />
        </div>

      <div className="input-group"> 
        <DropDown
          ref={activityRef}
          label="Dietary Profile"
          options={[
            { label: 'No Restrictions: No specific dietary limitations or preferences', value: 'No Restrictions' },
            { label: 'Vegetarian: Excludes meat, poultry, and seafood', value: 'Vegetarian' },
            { label: 'Vegan: Excludes all animal products, including meat, dairy, eggs, and often honey', value: 'Vegan' },
            { label: 'Pescatarian: Includes fish but excludes other meat and poultry', value: 'Pescatarian' },
            { label: 'Gluten-Free: Excludes foods containing gluten', value: 'Gluten-Free' },
            { label: 'Lactose-Free: Excludes dairy products containing lactose', value: 'Lactose-Free' },
            { label: 'Halal: Adheres to Islamic dietary laws', value: 'Halal' },
            { label: 'Kosher: Adheres to Jewish dietary laws', value: 'Kosher' },
            { label: 'Paleo: Focuses on whole foods like meat, fish, nuts, leafy greens, regional veggies, and seeds', value: 'Paleo' },
            { label: 'Ketogenic: A low-carbohydrate, high-fat diet', value: 'Ketogenic' },
            { label: 'Low-Carb: Restricts carbohydrates', value: 'Low-Carb' },
            { label: 'Low-Sodium: Suitable for those with high blood pressure or heart conditions', value: 'Low-Sodium' },
            { label: 'Low-FODMAP: Avoids certain carbohydrates that may cause digestive issues', value: 'Low-FODMAP' },
            { label: 'Ovo-Vegetarian: Includes eggs but excludes meat, poultry, seafood, and dairy', value: 'Ovo-Vegetarian' },
            { label: 'Lacto-Vegetarian: Includes dairy but excludes meat, poultry, seafood, and eggs', value: 'Lacto-Vegetarian' },
            { label: 'No Restrictions: No specific dietary limitations or preferences', value: 'No Restrictions' }
          ]}
          selected={formData.dietaryProfile} // Make sure to update this to the correct state property
          setSelected={(value) => handleChange(value, 'dietaryProfile')} // And this line
          nextInputRef={riskyBehaviourRef}
        />
      </div>
        
      <div className="input-group">
          <RiskyBehaviourCheckbox
            label="Increased Risk Behaviours:" 
            ref={riskyBehaviourRef}
            selectedRiskyBehaviours={selectedRiskyBehaviours}
            setSelectedRiskyBehaviours={setSelectedRiskyBehaviours}
            nextInputRef={drinkingRef}
            onChange={(newRiskyBehavioursObj) => {
              setFormData(prevData => ({ ...prevData, riskyBehaviours: newRiskyBehavioursObj }));
            }}
          />
        </div>     

      

        {/* 
        <div className="input-group">        
          <DropDown
            ref={sexualBehaviourRef}
            label="Sexual Behaviour"
            options={['Straight', 'Homosexual', 'Bisexual', 'Prefer not to say']}
            selected={formDatax.sexualOrientation}
            setSelected={(value) => handleChange(value, 'sexualOrientation')}
            nextInputRef={activityRef}
          />
        </div>
        */} 


        <div className="input-group"> 
        <DropDown
          ref={activityRef}
          label="Physical Activity"
          options={[
            { label: 'Sedentary (little or no exercise)', value: 'Sedentary' },
            { label: 'Lightly Active (light exercise/sports 1-3 days/week)', value: 'Lightly Active' },
            { label: 'Moderately Active (moderate exercise/sports 3-5 days/week)', value: 'Moderately Active' },
            { label: 'Very Active (hard exercise/sports 6-7 days a week)', value: 'Very Active' },
            { label: 'Super Active (very hard exercise, physical job, or training twice a day)', value: 'Super Active' }
          ]}
          selected={formData.fullPhysicalActivity}
          setSelected={(value) => handleChange(value, 'fullPhysicalActivity')} // Updated this line
          nextInputRef={interestRef}
        />
        </div>
        
        

        

        <div className="input-group">   
        <HealthInterestCheckbox
          ref={interestRef}
          selectedInterests={selectedInterests}
          setSelectedInterests={setSelectedInterests}
          onChange={(newInterestsObj) => {
            setFormData(prevData => ({ ...prevData, interests: newInterestsObj }));
          }}
        />
      </div>


        

      <button type="submit">Submit</button>
      </div>
    </form>
  </div>
  );
};

export default HealthProfileForm;
