import React, { useState, useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import UserMenu from '../UserMenu/UserMenu'
import FeatureModal from '../Modals/FeatureModal/FeatureModal';

import './HealthRiskBasedRecommendations.css';

const HealthRiskBasedRecommendations = () => {
  const { userHealthData } = useContext(UserContext);
  const data = userHealthData.riskBasedRecommendations;
  const [featureModalOpen, setFeatureModalOpen] = useState(false);
  const [clickedFeature, setClickedFeature] = useState("");
   

  const handleFeatureModalClose = () => {
    setFeatureModalOpen(false);
    setClickedFeature("");
  };

  const handleFeatureClick = (feature) => {
    setClickedFeature(feature);
    setFeatureModalOpen(true);
  };

  const [selectedBehaviour, setSelectedBehaviour] = useState(Object.keys(data || {})[0] || '');

  const selectBehaviour = (behaviour) => {
    setSelectedBehaviour(behaviour);
  };

  return (
    <div>
      <UserMenu 
        userHealthData={userHealthData} 
        handleFeatureClick={handleFeatureClick}
      />
      <FeatureModal 
        open={featureModalOpen} 
        feature={clickedFeature} 
        onClose={handleFeatureModalClose} 
      />


    <div className="health-risk-recommendations-container">
      <div className="health-risk-recommendations-header">
        {Object.entries(data || {}).map(([behaviour, info]) => (
          <div 
            key={behaviour}
            onClick={() => selectBehaviour(behaviour)}
            className={`health-risk-recommendation-card ${selectedBehaviour === behaviour ? 'active' : ''}`}
          >
            <h3 className="health-risk-recommendation-card-title">{info.title}</h3>
            <p className="health-risk-recommendation-count">{Object.keys(info.impact).length}</p>
            <p className="health-risk-recommendation-comment">
            Your current {info.title === "Obesity" ? "BMI" : info.title.toLowerCase()} increases the likelihood of {Object.keys(info.impact).length} diseases.
            </p>
          </div>
        ))}
      </div>
      <div className="health-risk-recommendations-details-container">
        <h2 className="health-risk-recommendations-details-header">{data[selectedBehaviour]?.title || ''} Recommendations</h2>
        <div className="health-risk-recommendations-details">
          {Object.entries(data[selectedBehaviour]?.impact || {}).map(([disease, info]) => (
            <div key={disease} className="health-risk-recommendation-detail">
              <h3 className="health-risk-recommendation-disease-title">{info.title}</h3>
              <p className="health-risk-recommendation-value">{info.value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  );
};

export default HealthRiskBasedRecommendations;
