// LandingPage.js

import React, { useContext} from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';
import { UserContext } from '../../contexts/UserContext';






const LandingPage = () => {

  const { user } = useContext(UserContext);

  return (
    <div>
      <div className="grid-container">
        <div className="panel panel-green">
        <img className="logo" src="/images/logo.png" alt="logo" /> {/* Add the logo here */}
          <h1>Welcome to Clarissa</h1>
          <p>Unlock your health potential with Clarissa, your trusted companion on the path to wellbeing. Discover simplifed, timely and personalised health information on screenings vaccinations and more, empowering confident decisions for you and your family</p>
        </div>

        <div className="panel panel-white">
          <h1>Did you know that</h1>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {/* replace with your own infographics */}
            <div className="infographic-stat-circle">
              <div className="infographic-circle-number">2-3</div>
              <div className="infographic-circle-comment">Million deaths prevented by vaccines</div>
            </div>
            <div className="infographic-stat-circle">
              <div className="infographic-circle-number">40%</div>
              <div className="infographic-circle-comment">Of breast cancer deaths can be prevented with regular mamograms</div>
            </div>
            <div className="infographic-stat-circle">
              <div className="infographic-circle-number">60%</div>
              <div className="infographic-circle-comment">Of colorectal cancer deaths can be prevented with regular screening</div>
            </div>
          </div>
        </div>
        {user ? (
          <Link to={{ pathname: "/dashboard" }} className="call-to-action-link">
            <div className="call-to-action">
              <h1>Go to your dashboard now</h1>
            </div>
          </Link>
        ) : (
          <Link to={{ pathname: "/login" }} className="call-to-action-link">
            <div className="call-to-action">
              <h1>Begin your journey now</h1>
            </div>
          </Link>
        )}

      </div>
    </div>
  );
};

export default LandingPage;