// config.js
const isProduction = process.env.NODE_ENV === 'production';

const apiUrl = isProduction
  ? 'https://clarissa-health-c7aaa8d6936e.herokuapp.com'
  : 'http://localhost:5001';

module.exports = {
  apiUrl,
};
