import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import './Navbar.css';

const Navbar = () => {
  const { user, clearUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    clearUser();
    navigate('/login');
  };

  return (
    <nav>
      <ul className="nav-left">
        <li>
          <Link to="/">Clarissa</Link>
        </li>
        {user ? (
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
        ) : null}
        <li>
          <Link to="/our-services">Our Services</Link>
        </li>
        <li>
          <Link to="/about-us">About Us</Link>
        </li>
      </ul>
      <ul className="nav-right">
        {user ? (
          <li>
            <button onClick={handleLogout}>Sign out</button>
          </li>
        ) : (
          <li>
            <button onClick={() => navigate('/login')}>Sign in</button>
          </li>
        )}
      </ul>
    </nav>
  );
};


export default Navbar;
