import React, { useState, useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import UserMenu from '../UserMenu/UserMenu'
import FeatureModal from '../Modals/FeatureModal/FeatureModal';


import './Vaccinations.css'; // Assuming you have this CSS file in the same directory

const Vaccinations = () => {



const { userHealthData } = useContext(UserContext); // Access userHealthData from UserContext
const [featureModalOpen, setFeatureModalOpen] = useState(false);
  const [clickedFeature, setClickedFeature] = useState("");


 
  

  const handleFeatureModalClose = (feature, feedback) => {
    
    setFeatureModalOpen(false);
    setClickedFeature("");
  };

  const handleFeatureClick = (feature) => {
    setClickedFeature(feature);
    setFeatureModalOpen(true);
  };

const data = userHealthData.vaccinations; // Assuming vaccinations is a field in userHealthData

const [expandedVaccinations, setExpandedVaccinations] = useState('current');

const currentVaccinations = Object.entries(data || {}).reduce((acc, [key, info]) => {
  if (info.currentRecommendation.currentBool) {
    acc[key] = info;
  }
  return acc;
}, {});

const historicVaccinations = Object.entries(data || {}).reduce((acc, [key, info]) => {
  if (info.historicRecommendation.historicBool) {
    acc[key] = info;
  }
  return acc;
}, {});

const vaccinationSection = expandedVaccinations === 'current' ? currentVaccinations : historicVaccinations;

const toggleVaccinationExpansion = (type) => {
  setExpandedVaccinations(type);
};

  

  return (
    <div>
      <UserMenu 
        userHealthData={userHealthData} 
        handleFeatureClick={handleFeatureClick}
      />
      <FeatureModal 
        open={featureModalOpen} 
        feature={clickedFeature} 
        onClose={handleFeatureModalClose} 
      />
      <div className="vaccinations-container">
        <div className="vaccinations-header">
          <div 
            onClick={() => toggleVaccinationExpansion('current')} 
            className={`vaccination-card ${expandedVaccinations === 'current' ? 'active' : ''}`}
          >
            <h3 className="vaccination-card-title">Current Vaccinations</h3>
            <p className="vaccination-count">{Object.keys(currentVaccinations).length}</p>
            <p className="vaccination-comment">
            {Object.keys(currentVaccinations).length} vaccinations are recommended for your lifestage</p>
          </div>
          <div 
            onClick={() => toggleVaccinationExpansion('historic')} 
            className={`vaccination-card ${expandedVaccinations === 'historic' ? 'active' : ''}`}
          >
            <h3 className="vaccination-card-title">Historic Vaccinations</h3>
            <p className="vaccination-count">{Object.keys(historicVaccinations).length}</p>
            <p className="vaccination-comment">
            {Object.keys(historicVaccinations).length} vaccinations should have occurred throughout your life.
            </p>
          </div>
        </div>
        <div className="vaccinations-details-container">
          <h2 className="vaccinations-details-header">{expandedVaccinations === 'current' ? 'Current' : 'Historic'} Vaccinations Required</h2>
          <div className="vaccinations-details">
            {Object.entries(vaccinationSection).map(([vaccine, info]) => (
              <div key={vaccine} className="vaccination-detail">
                <h3 className="vaccination-title">{info.title}</h3>
                <p className="vaccination-description">{expandedVaccinations === 'current' ? info.currentRecommendation.currentComment : info.historicRecommendation.historicComment}</p>
                <button onClick={() => handleFeatureClick('bookVaccination')}>Schedule vaccination appointment</button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vaccinations;


