import React, {useState, useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
//import UserMenu from '../UserMenu/UserMenu'
//import FeatureModal from '../Modals/FeatureModal/FeatureModal';
import './ScreeningsAndVaccinations.css'; 

const ScreeningsAndVaccinations = () => {
  const { userHealthData } = useContext(UserContext); 
  //const [featureModalOpen, setFeatureModalOpen] = useState(false);
  //const [clickedFeature, setClickedFeature] = useState("");

  const screeningData = userHealthData.screeningAndHealthPromotion;
  console.log(userHealthData)
  console.log(screeningData)

  const diseaseScreenings = Object.entries(screeningData || {}).reduce((acc, [key, info]) => {
    if (info.screeningRecommendation.diagnosis !== 'No screening required') {
      acc[key] = info;
    }
    return acc;
  }, {});

  const vaccinationData = userHealthData.vaccinations; // Assuming vaccinations is a field in userHealthData

  const currentVaccinations = Object.entries(vaccinationData || {}).reduce((acc, [key, info]) => {
    if (info.required === "Current") {
      acc[key] = info;
    }
    return acc;
  }, {});

  function renderTextWithLineBreaks(text) {
    return text.split('\n').map((str, index, array) => (
      <React.Fragment key={index}>
        {str}
        {index === array.length - 1 ? null : <>
          <br />
          <br />
        </>}
      </React.Fragment>
    ));
}
  
  const historicVaccinations = Object.entries(vaccinationData || {}).reduce((acc, [key, info]) => {
    if (info.required === "Historic") {
      acc[key] = info;
    }
    return acc;
  }, {});

  const totalVaccinations = Object.keys(currentVaccinations).length + Object.keys(historicVaccinations).length;
  
  const getFirstItem = (obj) => {
    const entries = Object.entries(obj);
    if (entries.length > 0) {
      return entries[0][1];
    }
    return null;
  }

  const [selectedDetail, setSelectedDetail] = useState(getFirstItem(diseaseScreenings));
  const [selectedFeature, setSelectedFeature] = useState('Screenings');

  
  
  const handleFeatureSelect = (feature) => {
    setSelectedFeature(feature);
    if (feature === 'Screenings') {
      setSelectedDetail(getFirstItem(diseaseScreenings));
    } else if (feature === 'Vaccinations') {
      setSelectedDetail(getFirstItem({...currentVaccinations, ...historicVaccinations}));
    }
  };  
  const handleDiseaseClick = (data) => {
    setSelectedDetail(data);
  };

  /* 
  const handleFeatureModalClose = () => {
    setFeatureModalOpen(false);
    setClickedFeature("");
  };

  const handleFeatureClick = (feature) => {
    setClickedFeature(feature);
    setFeatureModalOpen(true);
  };
  */





  return (
    <div>
      


      <div className="screenings-and-vaccinations-container">
        <div className= 'screenings-and-vaccinations-header-wrapper'>
          <div className="screenings-and-vaccinations-header">
            <div className="screenings-and-vaccinations-card">
              <h2 className="screenings-and-vaccinations-card-title">Screening And Vaccinations</h2>
              <h3 className="screenings-and-vaccinations-comment">
              You have {Object.keys(diseaseScreenings).length} screening recommendations and {totalVaccinations} vaccination recommendations
              </h3>
            </div>
          </div>
          <button
              className={`vaccination-screening-button ${selectedFeature === 'Screenings' ? 'selected-feature' : ''}`}
              onClick={() => handleFeatureSelect('Screenings')}>
              Screenings
          </button>

          <button
              className={`vaccination-screening-button ${selectedFeature === 'Vaccinations' ? 'selected-feature' : ''}`}
              onClick={() => handleFeatureSelect('Vaccinations')}>

              Vaccinations
          </button>
        </div>

        <div className="disease-cards-wrapper">
          {selectedFeature === 'Screenings' && (
            <>
              <div className='disease-card'>
                <h3>Screenings</h3>
                <p>Based on your profile, you should be looking into each of the below diseases, click down on individual diseases for more details</p>
                <ul>
                  {Object.entries(diseaseScreenings).map(([screening, info]) => (
                    <li key={screening} onClick={() => handleDiseaseClick(info)}
                    className={selectedDetail && selectedDetail.title === info.title ? 'selected-disease' : ''}
                    >
                      {info.shortenedTitle}</li>
                  ))}
                </ul>
              </div>
                {selectedDetail && (
                  <div className='disease-card'>
                    <h3>What Is {selectedDetail.title}</h3>
                    <p>{renderTextWithLineBreaks(selectedDetail.description)}</p>
                  </div>
                )}
                {selectedDetail && (
                  <div className='disease-card'>
                    <h3>Recommendation</h3>
                    <p>{renderTextWithLineBreaks(selectedDetail.screeningRecommendation.diagnosis)}</p>
                    <p>{renderTextWithLineBreaks(selectedDetail.screeningRecommendation.action)}</p>
                  </div>
                )}
            </>
            
          )}

          {selectedFeature === 'Vaccinations' && (
            <>
              <div className='disease-card'>
                <h3>Vaccinations</h3>
                <h4>Current Vaccinations</h4>
                <ul>
                  {Object.entries(currentVaccinations).map(([vaccination, info]) => (
                    <li key={vaccination} onClick={() => handleDiseaseClick(info)}
                    className={selectedDetail && selectedDetail.title === info.title ? 'selected-disease' : ''}
                    >
                      {info.shortenedTitle}</li>
                  ))}
                </ul>
                <h4>Historic Vaccinations</h4>
                <ul>
                  {Object.entries(historicVaccinations).map(([vaccination, info]) => (
                    <li key={vaccination} onClick={() => handleDiseaseClick(info)}
                    className={selectedDetail && selectedDetail.title === info.title ? 'selected-disease' : ''}
                    >
                      {info.shortenedTitle}</li>
                  ))}
                </ul>
              </div>

              {selectedDetail && (
                <div className='disease-card'>
                  <h3>What Is {selectedDetail.title}</h3>
                  <p>{renderTextWithLineBreaks(selectedDetail.description)}</p>
                </div>
              )}
              {selectedDetail && (
                <div className='disease-card'>
                  <h3>Diagnosis</h3>
                  <p>{renderTextWithLineBreaks(selectedDetail.vaccinationRecommendation.diagnosis)}</p>
                  <p>{renderTextWithLineBreaks(selectedDetail.vaccinationRecommendation.action)}</p>
                </div>
              )}
            </>
          )}


        </div>
      </div>
    </div>
  );
};

export default ScreeningsAndVaccinations;

