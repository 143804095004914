import React, { useState ,useRef, useEffect } from 'react';
import './DropDown.css';

const DropDown = ({ label, options, selected, setSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // 1. Add a ref


  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    // 2. Add event listener to the document
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isOpen) {
        setIsOpen(false); // 3. If click was outside dropdown and dropdown is open, close it
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);


  const handleSelect = (option) => {
    // Check if the option is an object with a label and value property
    // If it is, then call setSelected with the value property
    if (option && typeof option === 'object' && option.value) {
      setSelected(option.value);
    } else {
      setSelected(option);
    }
    setIsOpen(false);
  };


  return (
    <div className="dropdown-input-container" ref={dropdownRef}>
      <label className="dropdown-input-label">{`${label}:`}</label>
      <div className={`dropdown ${isOpen ? '' : 'closed'}`}>
        <div 
          className="dropdown-button" 
          onClick={toggleDropdown}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              toggleDropdown();
              event.preventDefault();
            }
          }}
          tabIndex="0"
        >
          {selected ? selected : 'Select an option'} <span className="dropdown-arrow">{isOpen ? '▲' : '▼'}</span>
        </div>
        {isOpen && (
          <ul>
            {options.map((option, index) => (
              <li 
                key={index} 
                onClick={() => handleSelect(option)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleSelect(option);
                    event.preventDefault();
                  }
                }}
                tabIndex="0"
              >
                {option.label || option} {/* Use the label property if it exists, otherwise use the option itself */}
              </li>
            ))}

          </ul>
        )}
      </div>
    </div>
  );
};

export default DropDown;

