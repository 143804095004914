import React, {useState, useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
//import UserMenu from '../UserMenu/UserMenu'
//import FeatureModal from '../Modals/FeatureModal/FeatureModal';
import './HealthBehaviours.css'; 

const HealthBehaviours = () => {
  const { userHealthData } = useContext(UserContext); 
  //const [featureModalOpen, setFeatureModalOpen] = useState(false);
  //const [clickedFeature, setClickedFeature] = useState("");

  const protectionBehaviours = userHealthData.protectionBasedRecommendations;
  const riskBehaviours = userHealthData.riskBasedRecommendations;

  console.log(protectionBehaviours)

  const protectionBehavioursArray = Object.keys(protectionBehaviours);
  const riskBehavioursArray = Object.keys(riskBehaviours);

  const formatKeyToTitle = (key) => {
    if (key === 'bmi') {
      return "BMI"
    }
    else{
      return key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1');
    }

}

function renderTextWithLineBreaks(text) {
  return text.split('\n').map((str, index, array) => (
    <React.Fragment key={index}>
      {str}
      {index === array.length - 1 ? null : <>
        <br />
        <br />
      </>}
    </React.Fragment>
  ));
}
    
const getFirstKey = (obj) => {
  const keys = Object.keys(obj);
  if (keys.length > 0) {
    return keys[0];
  }
  return null;
}

const firstProtectionBehaviourKey = getFirstKey(protectionBehaviours);
const firstDiseaseFromFirstBehaviour = protectionBehaviours[firstProtectionBehaviourKey] ? getFirstKey(protectionBehaviours[firstProtectionBehaviourKey].impact) : null;

const [selectedFeature, setSelectedFeature] = useState(firstProtectionBehaviourKey);
const [selectedDetail, setSelectedDetail] = useState(firstDiseaseFromFirstBehaviour ? protectionBehaviours[firstProtectionBehaviourKey].impact[firstDiseaseFromFirstBehaviour] : null);

const handleFeatureSelect = (feature) => {
  setSelectedFeature(feature);
  
  // Determine which behaviours to use
  const currentBehaviours = protectionBehaviours[feature] ? protectionBehaviours : riskBehaviours;

  const firstDisease = getFirstKey(currentBehaviours[feature].impact);
  setSelectedDetail(currentBehaviours[feature].impact[firstDisease]);
};

  const handleDiseaseClick = (data) => {
    setSelectedDetail(data);
};

const distinctDiseaseCount = (behaviours) => {
  // Flatten all disease arrays from each behaviour into a single array
  const allDiseases = [].concat(...Object.values(behaviours).map(b => Object.keys(b.impact)));
  
  // Convert array to a Set and back to array to deduplicate, then count the unique values
  return new Set(allDiseases).size;
}

const protectionImpactedDiseaseCount = distinctDiseaseCount(protectionBehaviours);
const riskImpactedDiseaseCount = distinctDiseaseCount(riskBehaviours);


  /* 
  const handleFeatureModalClose = () => {
    setFeatureModalOpen(false);
    setClickedFeature("");
  };

  const handleFeatureClick = (feature) => {
    setClickedFeature(feature);
    setFeatureModalOpen(true);
  };
  */




  return (
    <div>
      {/*
      <UserMenu 
        userHealthData={userHealthData} 
        handleFeatureClick={handleFeatureClick}
      />
      <FeatureModal 
        open={featureModalOpen} 
        feature={clickedFeature} 
        onClose={handleFeatureModalClose} 
      />
      */}


      <div className="health-behaviours-container">
        <div className= 'health-behaviours-header-wrapper'>
          <div className="health-behaviours-header">
            <div className="health-behaviours-card">
              <h2 className="health-behaviours-card-title">Health behaviours</h2>
              <h3 className="health-behaviours-comment">
              You have {Object.keys(protectionBehaviours).length} behaviours protecting against {protectionImpactedDiseaseCount} diseases and {Object.keys(riskBehaviours).length} behaviours increasing risk for {riskImpactedDiseaseCount} diseases
              </h3>
            </div>
          </div>
            {/* Render Protection Behaviours */}
            {protectionBehavioursArray.map(key => (
              <button
                className={`protection-behaviour-button ${selectedFeature === key ? 'selected-feature' : ''}`}
                onClick={() => handleFeatureSelect(key)}
              >
                {formatKeyToTitle(key)} {/* This will render "Dietary Habits" for "dietaryHabits" */}
              </button>
            ))}

            {/* Render Risk Behaviours */}
            {riskBehavioursArray.map(key => (
              <button
                className={`risk-behaviour-button ${selectedFeature === key ? 'selected-feature' : ''}`}
                onClick={() => handleFeatureSelect(key)}
              >
                {formatKeyToTitle(key)}
              </button>
            ))}
        </div>

        <div className="disease-cards-wrapper">
        {selectedFeature && (protectionBehaviours[selectedFeature] || riskBehaviours[selectedFeature]) && (
          <>
            {(() => {  // IIFE to allow variable declaration within JSX
              const currentBehaviours = protectionBehaviours[selectedFeature] ? protectionBehaviours : riskBehaviours;
              return (
                <div className='disease-card'>
                  <h3>{formatKeyToTitle(selectedFeature)}</h3>
                  <p>Your current {formatKeyToTitle(selectedFeature).toLowerCase()} are responsible for causing {Object.keys(currentBehaviours[selectedFeature].impact).length} diseases.</p>
                  <ul>
                    {Object.keys(currentBehaviours[selectedFeature].impact).map(disease => (
                      <li 
                        key={disease} 
                        onClick={() => handleDiseaseClick(currentBehaviours[selectedFeature].impact[disease])}
                        className={selectedDetail && selectedDetail.title === currentBehaviours[selectedFeature].impact[disease].title ? 'selected-disease' : ''}
                      >
                        {currentBehaviours[selectedFeature].impact[disease].shortenedTitle}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })()}
            
            {selectedDetail && (
              <div className='disease-card'>
                <h3>What Is {selectedDetail.title}?</h3>
                <p>{renderTextWithLineBreaks(selectedDetail.description)}</p> 
              </div>
            )}
            {selectedDetail && (
              <div className='disease-card'>
                <h3>Recommendation</h3>
                <p>{renderTextWithLineBreaks(selectedDetail.recommendation)}</p>
              </div>
            )}
          </>
        )}

        


        </div>
      </div>
    </div>
  );
};

export default HealthBehaviours;


/*
<div className="disease-cards-wrapper">
          {selectedFeature === 'Screenings' && (
            <>
              <div className='disease-card'>
                <h3>Screenings</h3>
                <p>Based on your profile, you should be looking into each of the below diseases, click down on individual diseases for more details</p>
                <ul>
                  {Object.entries(diseaseScreenings).map(([screening, info]) => (
                    <li key={screening} onClick={() => handleDiseaseClick(info)}
                    className={selectedDetail && selectedDetail.title === info.title ? 'selected-disease' : ''}
                    >
                      {info.title}</li>
                  ))}
                </ul>
              </div>
                {selectedDetail && (
                  <div className='disease-card'>
                    <h3>What Is {selectedDetail.title}?</h3>
                    <p>{selectedDetail.description}</p>
                  </div>
                )}
                {selectedDetail && (
                  <div className='disease-card'>
                    <h3>Diagnosis</h3>
                    <p>{selectedDetail.screeningRecommendation}</p>
                  </div>
                )}
                {selectedDetail && (
                  <div className='disease-card'>
                    <h3>Action</h3>
                    <p>{selectedDetail.screeningRecommendation}</p>
                  </div>
                )}
            </>
            
          )}

          {selectedFeature === 'Vaccinations' && (
            <>
              <div className='disease-card'>
                <h3>Vaccinations</h3>
                <h4>Current Vaccinations</h4>
                <ul>
                  {Object.entries(currentVaccinations).map(([vaccination, info]) => (
                    <li key={vaccination} onClick={() => handleDiseaseClick(info)}
                    className={selectedDetail && selectedDetail.title === info.title ? 'selected-disease' : ''}
                    >
                      {info.title}</li>
                  ))}
                </ul>
                <h4>Historic Vaccinations</h4>
                <ul>
                  {Object.entries(historicVaccinations).map(([vaccination, info]) => (
                    <li key={vaccination} onClick={() => handleDiseaseClick(info)}
                    className={selectedDetail && selectedDetail.title === info.title ? 'selected-disease' : ''}
                    >
                      {info.title}</li>
                  ))}
                </ul>
              </div>

              {selectedDetail && (
                <div className='disease-card'>
                  <h3>What Is {selectedDetail.title}?</h3>
                  <p>{selectedDetail.description}</p>
                </div>
              )}
              {selectedDetail && (
                <div className='disease-card'>
                  <h3>Diagnosis</h3>
                  <p>{selectedDetail.currentRecommendation.currentBool === true ? selectedDetail.currentRecommendation.currentComment : selectedDetail.historicRecommendation.historicComment}</p>
                </div>
              )}
              {selectedDetail && (
                <div className='disease-card'>
                  <h3>Action</h3>
                  <p>{selectedDetail.currentRecommendation.currentBool === true ? selectedDetail.currentRecommendation.currentComment : selectedDetail.historicRecommendation.historicComment}</p>
                </div>
              )}
            </>
          )}


<div className="health-behaviours-details-container">
<h2 className="health-behaviours-details-header">Screenings Required</h2>
<div className="health-behaviours-details">
  {Object.entries(diseaseScreenings).map(([screening, info]) => (
    <div key={screening} className="health-behaviours-detail">
      <h3 className="health-behaviours-title">{info.title}</h3>
      <p className="health-behaviours-description">{info.screeningRecommendation}</p>
    </div>
  ))}
</div>
</div>
</div>
</div>
);
};

*/