export const diseases = [
    "Heart Disease",
    "Stroke",
    "Type One Diabetes",
    "Type Two Diabetes",
    "High Blood Pressure",
    "High Cholesterol",
    "Breast Cancer",
    "Colorectal (Bowel) Cancer",
    "Lung Cancer",
    "Osteoporosis",
    "Cervical Cancer",
    "HIV",
    "None Of The Above",
  ];

  export  const riskyBehaviours = [
    "Engage/Engaged In IV Drug Use",
    "Had Sex With A Man Who Has Sexual History With Men",
    "Have A Partner Diagnosed With HIV",
    "Have Multiple Sexual Partners",
    "Have An STD",
    "Travel To High Risk Travel Countries",
    "None Of The Above",
  ];
  
  export  const riskyBehaviourMapping = {
    "ivDrugUse": "Engage/Engaged In IV Drug Use",
    "sexWithMen": "Had Sex With A Man Who Has Sexual History With Men",
    "partnerHIV": "Have A Partner Diagnosed With HIV",
    "multipleSexualPartners":"Have Multiple Sexual Partners",
    "haveSTD": "Have An STD",
    "highRiskTravel": "Travel To High Risk Travel Countries",
    "none" : "None Of The Above",
  };


  export const healthInterests = [
    "Disease Screening",
    "Vaccinations",
    "Weight Loss",
    "Strength",
    "Stress Management",
    "Sleep",
    "None Of the Above",
    // Add any more areas of interest here
  ];

  export const healthInterestMapping = {
    "diseaseScreening": "Disease Screening",
    "vaccinations": "Vaccinations",
    "weightLoss": "Weight Loss",
    "strength": "Strength",
    "stressManagement": "Stress Management",
    "sleep": "Sleep",
    "none": "None Of The Above",
    // ... add other interests here
  };

  export const healthInterestMappingAlt = {
    "Disease Screening": "diseaseScreening", 
    "Vaccinations": "vaccinations",
    "Weight Loss": "weightLoss",
    "Strength": "strength",
    "Stress Management": "stressManagement",
    "Sleep": "sleep",
    "None Of The Above":"none",
    // ... add other interests here
  };

  export const BMI_CATEGORIES = {
    UNDERWEIGHT: {
        range: [0, 18.5],
        label: "underweight"
    },
    NORMAL: {
        range: [18.5, 24.9],
        label: "normal"
    },
    OVERWEIGHT: {
        range: [25, 29.9],
        label: "overweight"
    },
    OBESE: {
        range: [30, Infinity],
        label: "obese"
    }
};

