import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import './RegisterForm.css'
const { apiUrl } = require('../../config');

const RegisterForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(null);
    
    if (password !== confirmPassword) {
      setErrorMessage("Passwords don't match");
      return;
    }
  
    try {
      const registerResponse = await axios.post(`${apiUrl}/users/register`, { email, password, username });
      
      const tempUser = {
        token: registerResponse.data.data.token,  // updated this line
        email: email,
        username: username,
        healthData: registerResponse.data.data.healthData,  // updated this line
        id: registerResponse.data.data.id,  // updated this line
      };
  
      const healthDataResponse = await axios.get(`${apiUrl}/users/user/${tempUser.id}/health-data`, {
        headers: { Authorization: `Bearer ${tempUser.token}` }
      });
  
      // Update the user health data in the temporary user object
      tempUser.healthData = healthDataResponse.data;
  
      // Now update the user state and the local storage
      setUser(tempUser);
      localStorage.setItem('user', JSON.stringify(tempUser));
      navigate('/health-profile');  // redirect to home page (or wherever you want to take the user upon registration)
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  };
  

  return (
    <div className="register-form">
        <h2>Clarissa</h2>
        <h2>Register</h2>
        <form onSubmit={handleSubmit}>
            <input type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="Username" required />
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
            <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" required />
            <input type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} placeholder="Confirm Password" required />
            <div className="terms-section">
              <label htmlFor="terms">I agree to the <Link to="/terms-and-conditions">Terms and Conditions</Link> and <Link to="/privacy-policy">Privacy Policy</Link></label>
              <input type="checkbox" id="terms" required />
            </div>

            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button type="submit">Register</button>
        </form>
        <div className="login-section">
            <p>Already have an account?</p>
            <button onClick={() => navigate('/login')}>Login</button>
        </div>
    </div>
  );

};

export default RegisterForm;

