import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import './Dashboard.css';
// import CircularProgress from '../CircularProgress/CircularProgress';
import FeatureModal from '../Modals/FeatureModal/FeatureModal';
import ActivityModal from '../Modals/ActivityModal/ActivityModal';
// import UserMenu from '../UserMenu/UserMenu';
//const { apiUrl } = require('../../config');


const Dashboard = () => {
  const { userHealthData, loading } = useContext(UserContext);
  const [activeCard, setActiveCard] = useState(null);
  const [featureModalOpen, setFeatureModalOpen] = useState(false);
  const [activityModalOpen, setActivityModalOpen] = useState(false);
  const [clickedFeature, setClickedFeature] = useState("");
  const navigate = useNavigate();
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  // const [stepData, setStepData] = useState(null);


  
 
  const handleFeatureModalClose = () => {
    setFeatureModalOpen(false);
    setClickedFeature("");
  };

  const handleFeatureClick = (feature) => {
    setClickedFeature(feature);
    setFeatureModalOpen(true);
  };
  
  
  const summaryData = useMemo(() => {
    return userHealthData ? {
      riskBasedRecommendations: userHealthData.riskBasedRecommendations
      ? Object.entries(userHealthData.riskBasedRecommendations).length
      : 0,
      protectionBasedRecommendations: userHealthData.protectionBasedRecommendations
      ? Object.entries(userHealthData.protectionBasedRecommendations).length
      : 0,
      protectionBehaviours: userHealthData.protection_behaviours
        ? userHealthData.protection_behaviours.length
        : 0,
      currentVaccinations: userHealthData.vaccinations
        ? Object.entries(userHealthData.vaccinations).filter(([_, info]) => info.required === "Current").length
        : 0,
      historicVaccinations: userHealthData.vaccinations
        ? Object.entries(userHealthData.vaccinations).filter(([_, info]) => info.required === "Historic").length
        : 0,
      diseaseScreenings: userHealthData.screeningAndHealthPromotion
        ? Object.entries(userHealthData.screeningAndHealthPromotion).filter(([_, info]) => info.screeningRecommendation.diagnosis !== 'No screening required').length
        : 0,
    } : {};
  }, [userHealthData]);
  
  const totalVaccinations = summaryData.currentVaccinations + summaryData.historicVaccinations;
  

  

  /* 
  const handleCardClick = (linkPath, modalMessage, summaryData, modalTitle, additionalState = null) => {
    if (summaryData === 0) {
      handleModalOpen(modalMessage, modalTitle);
    } else {
      navigate(linkPath, { state: additionalState });
    }
  };
  */


  useEffect(() => {
    // Check if there's a need to fetch step data, based on some condition or local storage
    const shouldFetchStepData = localStorage.getItem('fetchStepData');
    if (shouldFetchStepData === 'true') {
      // remove the indicator
      localStorage.removeItem('fetchStepData');
      
      // Fetch the step data
      fetchStepData();
    }
  }, []); // Empty dependency array to run only once

  /* 
  const handleTrackFitnessClick = () => {
    // Set an indicator in local storage before redirecting
    localStorage.setItem('fetchStepData', 'true');

    const callbackUrl = window.location.href;  // callbackUrl can be the current url
    const url = `${apiUrl}/google-fitness/auth?userid=${user.id}&callbackUrl=${encodeURIComponent(callbackUrl)}`;
    window.location.href = url;

    // Removed the fetchStepData call here, as it will now happen in the useEffect
  };
  */
  
  const handleActivityModalClose = () => {
    setActivityModalOpen(false);
    setModalTitle("");  
    setModalMessage(""); 
  };
/*
  const handleModalOpen = (modalMessage, modalTitle) => {
    setActivityModalOpen(true);
    setModalMessage(modalMessage);
    setModalTitle(modalTitle);  // setting the title
  };
  */


  const handleCardClose = () => {
    setActiveCard(null);
  };

  const handleOutsideClick = useCallback((e) => {
    if (e.target.closest('.activity-card')) return; // if clicked inside the active card, do nothing
    handleCardClose(); // otherwise close the active card
  }, []);

  useEffect(() => {
    // listen for clicks outside the active card when it's open
    if (activeCard) {
      window.addEventListener('click', handleOutsideClick);
    }

    // cleanup listener
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };

    
  }, [activeCard, handleOutsideClick]);



  if (loading) {
    return <p>Loading...</p>;
  }

  const fetchStepData = async () => {
    try {
      // I'm assuming you're using the fetch API here, you may be using axios or some other library
      const response = await fetch('http://localhost:5001/google-fitness/steps', {
        credentials: 'include', // to include cookies, you need to set this
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log('Step Data:', data)
        // do something with step data, for example, set it in state
        //setStepData(data);

      } else {
        throw new Error('Failed to fetch step data');
      }
    } catch (error) {
      console.error(error);
    }
  }

  // If userHealthData is null, render a fallback UI
if (userHealthData === null) {
  return <p>Loading</p>;
}
  
return (
  <div>
      <FeatureModal 
          open={featureModalOpen} 
          feature={clickedFeature} 
          onClose={handleFeatureModalClose} 
      />
      <ActivityModal 
          open={activityModalOpen} 
          onClose={handleActivityModalClose} 
          title={modalTitle}
          message={modalMessage}      
      />

      
      <div className="dashboard-container">
          <div className="content-area">
              <div className="welcome-message">
                  <h1>Hi there,</h1>
                  <p>Welcome to your personalised health dashboard</p>
              </div>
              <div className='main-section'>
                <div className="wrapper-container">

                  <div className='live-well-section-wrapper'>
                    <h2 className="section-title" colSpan={2}>Your Health Screenings</h2>

                      <div className="stay-well-logo-container">
                          <img className="stay-well-screening-logo" src="/images/screening.png" alt="logo"/>
                      </div>

                    <div className="section">
                      <h3>You have {summaryData.diseaseScreenings || "no"} screening activities to be aware of</h3>
                      {userHealthData.vaccinations ? (
                          <>
                              <h3>And {totalVaccinations || "no"} vaccinations to potentially receive</h3>
                          </>
                      ) : (
                          <p>Loading vaccinations data...</p>
                      )}
                      <button className="details-button" onClick={() => navigate("/screenings-and-vaccinations")}>See full details</button>
                    </div>
                  </div>


                    <div className='live-well-section-wrapper'>
                      <h2 className="section-title" colSpan={2}>Your Health Behaviours</h2>
                      
                        <div className="stay-well-logo-container">
                            <img className="stay-well-screening-logo" src="/images/behaviours.png" alt="logo"/>
                        </div>
                        
                        <div className="section">
                          <h3>You have {summaryData.protectionBasedRecommendations || "no"} behaviours protecting against future disease risk</h3>
                          <h3>And {summaryData.riskBasedRecommendations || "no"} behaviours increasing your risk of future diseases</h3>
                          <button className="details-button" onClick={() => navigate("/health-behaviours")}>See full details</button>
                        </div>
                    </div>
                  </div>
                    



                  <div className='stay-well-section'>
                      <div className='goals-section' onClick={() => handleFeatureClick('dailyProgress')}>
                          <div className='goals-header'>
                              <h2>Your Health Plan</h2>
                          </div>
                      </div>
                      <div className="live-well-logo-container">
                              <img className="live-well-health-plan-logo" src="/images/healthplan.png" alt="logo"/>
                      </div>

                      <div className="health-plan-text">
                        <p>Stay healthy with your very own personalized health plan encompassing fitness, diet, sleep, mental health and more!</p>
                      </div>

                      <button className="health-plan-button">Create your health plan!</button>


                      
                  </div>
              </div>
          </div>
      </div>
  </div>
);
}
export default Dashboard;

