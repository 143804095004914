import { useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';
import dayjs from "dayjs";
import { BMI_CATEGORIES } from '../components/constants';
const { apiUrl } = require('../config');





const useUpdateHealthData = (initialState) => {
  const [formData, setFormData] = useState(initialState);
  const { setUserHealthData } = useContext(UserContext);
  console.log(formData)

  const calculateBMICategory = (bmi) => {
    for (const category in BMI_CATEGORIES) {
        const [min, max] = BMI_CATEGORIES[category].range;
        if (bmi >= min && bmi <= max) {  // Notice I included the lower range too
            return BMI_CATEGORIES[category].label;
        }
    }
    return 'unknown';  // Default return value
};


  const mapPhysicalActivity = (activity) => {
    if (activity === 'Sedentary') {
      return 'low';
    } else if (activity === 'Lightly Active' || activity === 'Moderately Active') {
      return 'medium';
    } else {
      return 'high';
    }
  };
  

  const handleSubmit = async (e, onSuccess) => {
    e.preventDefault();

    const now = dayjs();
    const dob = dayjs(formData.dob);
    const age = now.diff(dob, 'year');
  
    const smokeBoolean = formData.smoke === "Current smoker";
    const pregnantBoolean = formData.pregnant === "Yes";

    const newSmokingDetails = {}
    if (formData.smoke === "Current smoker") {
      newSmokingDetails.smokingStatus = formData.smoke;
      newSmokingDetails.cigarettesPerDay= formData.smokingDetails;
      newSmokingDetails.yearsSinceQuitSmoking = 0
    }

    else if (formData.smoke === 'Previously a smoker'){
      newSmokingDetails.smokingStatus = formData.smoke;
      newSmokingDetails.cigarettesPerDay= 0;
      newSmokingDetails.yearsSinceQuitSmoking = formData.smokingDetails
    }

    else{
      newSmokingDetails.smokingStatus = formData.smoke;
      newSmokingDetails.cigarettesPerDay= 0;
      newSmokingDetails.yearsSinceQuitSmoking = 0
    }

    
    const bmiValue = parseFloat((parseFloat(formData.weightKgs)/((parseFloat(formData.heightCms)/100)*(parseFloat(formData.heightCms)/100))).toFixed(1));

    console.log("Form data at the time of submission", formData);
    const modifiedData = {
        ...formData,
        sex: formData.sex.toLowerCase(),
        heightCms: parseFloat(formData.heightCms),
        heightFt: parseFloat(formData.heightFt),
        heightInches: parseFloat(formData.heightInches),
        weightKgs: parseFloat(formData.weightKgs),
        weightLbs: parseFloat(formData.weightLbs),


        age: age,
        /*drugUse: {
          drugUseStatus: formData.drugUseStatus,
          drugs: formData.drugUseStatus === 'Never used drugs' ? 'N/A' : formData.drugs,
          drugUseFrequency: formData.drugUseStatus === 'Never used drugs' ? 'N/A' : formData.drugUseFrequency
        },*/
        smoke: smokeBoolean,
        smokingDetails: newSmokingDetails,
        pregnant: pregnantBoolean,
        physicalActivity: mapPhysicalActivity(formData.fullPhysicalActivity),
        bmi: bmiValue,
        bmiCategory: calculateBMICategory(bmiValue)


      };

    const user = JSON.parse(localStorage.getItem('user'));
    console.log("Updating health data")
    console.log(user)
    const token = user && user.token;
    const userId = user && user.id;
    console.log(token)
    console.log(modifiedData)
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // Replace `token` with your actual token value
        'Content-Type': 'application/json',
  },
    };

    console.log(config)

    try {
        console.log("Form data at the time before making put request", modifiedData);
        const response = await axios.put(`${apiUrl}/users/user/${userId}/update/healthProfile`, {personalData: modifiedData}, config);
        console.log('data submitted', response.data);
        
        // Update health data in context
        setUserHealthData(response.data);
        
        // Update user data in local storage
        const updatedUser = {...user, healthProfile: response.data};
        localStorage.setItem('user', JSON.stringify(updatedUser));
      
        alert('Health data updated successfully');
        onSuccess && onSuccess();  // Call the onSuccess function if it's provided

      } catch (error) {
        console.error(error);
        alert('Failed to update health data');
      }
      
  };

  return [formData, setFormData, handleSubmit];
};

export default useUpdateHealthData;
