import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RegisterForm from './components/RegisterForm/RegisterForm';
import LoginForm from './components/LoginForm/LoginForm';
import Dashboard from './components/Dashboard/Dashboard';
import Vaccinations from './components/Vaccinations/Vaccinations';
import Screenings from './components/Screenings/Screenings';
import ScreeningsAndVaccinations from './components/ScreeningsAndVaccinations/ScreeningsAndVaccinations';
import HealthRiskBasedRecommendations from './components/HealthRiskBasedRecommendations/HealthRiskBasedRecommendations';
import HealthProtectionBasedRecommendations from './components/HealthProtectionBasedRecommendations/HealthProtectionBasedRecommendations';
import HealthBehaviours from './components/HealthBehaviours/HealthBehaviours';

import HealthProfileForm from './components/HealthProfileForm/HealthProfileForm';
import LandingPage from './components/LandingPage/LandingPage'
import OurServices from './components/OurServices/OurServices'
import AboutUs from './components/AboutUs/AboutUs'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions'
import Navbar from './components/Navbar/Navbar';  // Import Navbar here
import { UserContext } from './contexts/UserContext';

const App = () => {
  const { user } = useContext(UserContext);

  return (
    <Router>
      <Navbar />  {/* Navbar goes here */}
      <Routes>
        <Route path="/" element={<LandingPage />} /> {/* this is the new route */}
        <Route path="/our-services" element={<OurServices />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/dashboard" element={user ? <Dashboard /> : <LoginForm />} />
        <Route path="/health-profile" element={user ? <HealthProfileForm /> : <LoginForm />} />
        <Route path="/vaccinations" element={user ? <Vaccinations /> : <LoginForm />} />  
        <Route path="/screenings" element={user ? <Screenings /> : <LoginForm />} />  
        <Route path="/screenings-and-vaccinations" element={user ? <ScreeningsAndVaccinations /> : <LoginForm />} />  
        <Route path="/health-risk-based-recommendations" element={user ? <HealthRiskBasedRecommendations /> : <LoginForm />} />  
        <Route path="/health-protection-based-recommendations" element={user ? <HealthProtectionBasedRecommendations /> : <LoginForm />} />  
        <Route path="/health-behaviours" element={user ? <HealthBehaviours /> : <LoginForm />} />  

        
      </Routes>
    </Router>
  );
}

export default App;

