import React, { useState, useEffect } from 'react';
import './ActivityModal.css';

const ActivityModal = ({ open, onClose, title, message }) => {
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    setIsBusy(false);
  }, [open]);

  const handleCloseClick = (e) => {
    e.stopPropagation();
    onClose();
  };


  if (!open) {
    return null;
  }

  return (
    <div className='modal-overlay' onClick={handleCloseClick}>
      <div className='modal-content' onClick={e => e.stopPropagation()}>
        <button className='modal-close-button' onClick={handleCloseClick}>X</button>
        <h3>{title || "No Title"}</h3>
        <p>{message}</p>
        <div className='activity-modal-buttons'>
            <button disabled={isBusy} onClick={handleCloseClick}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ActivityModal;
