import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
const { apiUrl } = require('../config');

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');

    if (savedUser) {
      return JSON.parse(savedUser);
    } else {
      return null;
    }
  });

  const [userHealthData, setUserHealthData] = useState(null);
  const [loading, setLoading] = useState(true); // Initialize loading state as true
  
  useEffect(() => {
    console.log(userHealthData); // print out the user health data
  }, [userHealthData]);

  useEffect(() => {
    // If user data exists, load the health data
    if(user) {
      axios.get(`${apiUrl}/users/user/${user.id}/profile/healthProfile`, {
        headers: { Authorization: `Bearer ${user.token}` }})
        .then(response => {
          console.log(response.data)
          setUserHealthData(response.data);
          setLoading(false); // Set loading to false when data is loaded
        })
        .catch(error => {
          console.error(error);
          setLoading(false); // Also set loading to false on error to stop the loading indicator
        });
    } else {
      setLoading(false); // If there's no user, set loading to false
    }
  }, [user]);

  const clearUser = () => {
    setUser(null);
    setUserHealthData(null);
    localStorage.removeItem('user');
  };

  // Return early if loading
  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <UserContext.Provider value={{ user, setUser, userHealthData, setUserHealthData, clearUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};
