import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import './FeatureModal.css';
const { apiUrl } = require('../../../config');

const featureDescriptions = {
  nutrition: "The nutrition feature will provide users with personalised nutrition plans for that users can then track their daily caloric intake against to meet their goals",
  mindfulness: "The mindfulness feature will provide guided meditations to help users find inner peace in a stressful world",
  trackFitness: "The fitness feature will integrate with your smart device to track key statistics such as resting heart rate, calories burned and much more",
  insurance: "The insurance feature will allow users to find the right insurance plan for them and integrate into the application so that they can match their health needs with their entitlements",
  ai: "The ask ai feature will provide catered ai responses to your health questions based on your specific requirements",
  appointment: "The appointments feature will automate you appoitnment booking taking the hassle away from making arrangments with your practitioners",
  dailyProgress: "The daily progress feature will let you add specific goals catered to your health needs",
  bookVaccination: "The book vaccination feature will help users find the nearest vaccination centres and book appointments in a few easy steps",
  bookScreening:"The book screening feature will help users find the nearest screening centres and book appointments in a few easy steps"


  // add other feature descriptions here
};

const FeatureModal = ({ open, onClose, feature }) => {
  
  const [isBusy, setIsBusy] = useState(false);
  const [isCommentBoxVisible, setCommentBoxVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [wantFeature, setWantFeature] = useState(false); // New state variable
  const featureDescription = featureDescriptions[feature] || "";

  const { user } = useContext(UserContext);
  
  const token = user ? user.token : '';

  useEffect(() => {
    setIsBusy(false);
    setCommentBoxVisible(false);
    setComment("");
    setWantFeature(false); // Reset to 'false' every time the modal opens
  }, [open]);

  const handleCloseClick = (e) => {
    e.stopPropagation();
    // If the comment box is visible but no comment has been submitted, submit the feedback
    if (isCommentBoxVisible && isBusy) {
      handleSubmitFeedback();
    }
    onClose();
  };

  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleButtonClick = (confirm) => {
    setIsBusy(true);
    setWantFeature(confirm);
    setFeedbackMessage(
      confirm 
        ? "Thank you for your feedback, please let us know what you would like to see in this feature"
        : "Thank you for your feedback."
    );

    if (confirm) {
      setCommentBoxVisible(true);
    } else {
      handleSubmitFeedback(); // Send feedback when 'No' button is clicked
      setTimeout(() => {
        onClose(confirm);
      }, 2000);
    }
  };

  const handleSubmitFeedback = () => { 
    // You can use the `comment` and `wantFeature` state variables here to send the comment to the server
    
    if (!user || !user.email) {
      console.error('User data not available.');
      return;
    }
  
    console.log(user.email)
    fetch(`${apiUrl}.herokuapp.com/feedback/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        user: user.email, // send the username
        email: user.email, // send the email
        feature: feature,
        wantFeature: wantFeature,
        comment: comment,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        setTimeout(() => {
          onClose(true);
        }, 2000);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleSubmitComment = () => {
    setIsBusy(true);
    handleSubmitFeedback();
  };

  if (!open) {
    return null;
  }

  return (
    <div className='feature-modal-overlay' onClick={handleCloseClick}>
      <div className='feature-modal-content' onClick={e => e.stopPropagation()}>
        <button className='feature-modal-close-button' onClick={handleCloseClick}>X</button>
        <h3>Sorry, this feature is not currently available</h3>
        <p>{featureDescription}</p>
        <p>{isBusy ? feedbackMessage : "Is this feature something you would find useful?"}</p>
        {!isCommentBoxVisible && (
          <div className='feature-modal-buttons'>
            <button disabled={isBusy} onClick={() => handleButtonClick(true)}>Yes</button>
            <button disabled={isBusy} onClick={() => handleButtonClick(false)}>No</button>
          </div>
        )}
        {isCommentBoxVisible && (
          <div>
            <textarea className='feature-modal-textarea' onChange={(e) => setComment(e.target.value)} value={comment} placeholder="Please let us know what you would like to see in this feature"/>
            <button className='feature-modal-submit' onClick={handleSubmitComment}>Submit</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeatureModal;