import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import './DateInput.css';

const DateInput = ({ label, selectedDate, setValue }) => {
  return (
    <div className="date-input-container">
      <label className="date-input-label">{label}</label>
      <div className="date-picker-wrapper">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={selectedDate}
            onChange={(date) => setValue(date ? date.format('YYYY-MM-DD') : '')} 
            renderInput={(params) => <input {...params} placeholder="dd/mm/yyyy" className="date-input" />}
            sx={{
              ".MuiInputBase-root": { 
                backgroundColor: '#c9f4e4',
                width: '400px',
                height: '38.5px',
                padding: '10px',
                lineHeight: '1.5', 
                fontSize: '1em',
                textAlign: 'center',
                border: 'none',
                borderRadius: '4px',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#a8e8d4',
                },
                '&.Mui-focused': { 
                  backgroundColor: '#a8e8d4',
                },
              },
              // Add this line to adjust the popper width
              ".MuiPickersModal-dialog": {
                width: '350px'
              },
            }}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default DateInput;
