import React from 'react';
import './AboutUs.css'; 

const AboutUs = () => {
    return (
        <div className="container">
            <div className="about-us">
                <h1>Our Story</h1>
                <p>Online healthcare information is siloed, complex and is not personalized to the needs of the individual. Clarissa is a web-based platform that provides healthcare consumers with personalized, digestible and intelligent insights about their health and wellbeing.</p>
                
            </div>

            <div className="our-story">
                <div className="story-content">
                    <img src="/images/clara.jpg" alt="Clara" />
                    <div className="story-text">
                        <h2>“You must never think of anything except the need, and how to meet it.” - Clarissa, ‘Clara’ Barton​</h2>
                        <p>Clarissa Barton’s life of service is a role model for generations of doctors, nurses, teachers, social workers and more. As the founder of the American Red Cross in 1881, she exhibited a profound dedication to meeting need in a universal, human, brave and transparent way.</p>
                        <p>Our values are inspired by her life, hence our name Clarissa (meaning ‘bright, intelligent).</p>
                        <p>At Clarissa, we understand that a modern-day health journey starts online. In fact, 97% of US consumers search online before seeing their doctor. We know online healthcare information can be siloed, confusing and inaccurate, but the majority of consumers still continue to search online.</p>
                        <p>We think there is a need to better serve our communities through providing personalised, simple and accurate information, anywhere, anytime.</p>
                        <p>That is why we created Clarissa; your simple digital front door to wellness.</p>
                    </div>
                </div>
            </div>

            <div className="team">
                <h1>Meet the Team</h1>
                <div className="team-member">
                    <div className="team-member-top">
                    <img src="/images/sam.jpg" alt="Sam Harding" />
                        <div className="team-member-info">
                            <h2>Sam Harding</h2>
                            <p><a href="mailto:sharding@clarissahealth.com">sharding@clarissahealth.com</a></p>
                        </div>
                        
                    </div>
                    <p>Sam Harding is the CEO and co-founder of Clarissa.</p>
                    <p>Sam’s passion for healthcare, service and the power of technology to deliver transformational outcomes for consumers prompted her to start Clarissa. Prior to this, she was an experienced management consultant (ex-EY), focused in digital health.</p>
                    <p>She did not see solutions that were actively supporting consumers to elevate their understanding of their health and wellbeing. Solutions need to enable proactive, personalised and preventative health promotion activity, and reduce the complexity seen in healthcare today.</p>
                    <p>So she created Clarissa. </p>
                    <p>Sam earned a Master in Public Health (First Class Honours) which focused on the critical factors to implement new and emerging technology successfully in complex organisations. </p>
                </div>
                <div className="team-member">
                    <div className="team-member-top">
                        <img src="/images/cale.jpg" alt="Sam Harding" />
                        <div className="team-member-info">
                            <h2>Cale James</h2>
                            <p><a href="mailto:cjames@clarissahealth.com">cjames@clarissahealth.com</a></p>
                        </div>
                    </div>
                    <p>Cale James is the COO and co-founder of Clarissa. Cale’s passion is in exploring innovative technologies with the potential to improve the world. Clarissa is his vehicle to do so.</p>
                    <p>Prior to this, Cale spent years in traditional management consulting, before pivoting to advising early-stage start ups in the burgeoning world of web3.</p>
                    <p>From his experience, he brings a range of skills across finance, operations and emerging technologies to help fuel Clarissa’s growth.</p>
                    <p>Cale has earned a Bachelors degree in Finance, Economics and Statistics from the University of Auckland in 2017.</p>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;
