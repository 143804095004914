import React from 'react';
import './NumberInput.css';

const NumberInput = ({ label, value, setValue, className, placeholder }) => {
  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className={`number-input-container ${className || ''}`}>  {/* Use the className prop here */}
      {label && <label className="number-input-label">{`${label}:`}</label>}
      <input
        className="number-input"
        placeholder = {placeholder}
        type="number"
        value={value || ''}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default NumberInput;





