import React, { useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import './GooglePlacesInput.css'

const GooglePlacesInput = ({ onSelect, value }) => {
  const [address, setAddress] = useState(value || "");


  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => onSelect({ formattedAddress: address, ...latLng }))
      .catch((error) => console.error("Error fetching lat/lng", error));
  };

  const inputStyle = {
    backgroundColor: "#c9f4e4",
    color: "black",
    height: "38.5px",
    width: "400px",
    padding: "10px",
    margin: "5px",
    textAlign: "center",
    border: "none",
    borderRadius: "4px",
    zIndex: 1,
    fontFamily: "Arial, sans-serif"
    

  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
        <div className="address-input-container">
        <label htmlFor="location-input">Address:</label> {/* Added label */}
          <input
            {...getInputProps({
              placeholder: "Enter Location",
              className: "form-control",
              style: inputStyle,
            })}
          />
        </div>

          <div>
            {loading ? <div>Loading...</div> : null}
            {suggestions.map((suggestion) => {
              const style = {
                backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                cursor: "pointer",
                padding: "10px",
              };
              return (
                <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlacesInput;



/*




import React, { useRef } from "react";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";

const GooglePlacesInput = () => {
    const inputRef = useRef();

    const handlePlaceChanged = () => { 
        const [ place ] = inputRef.current.getPlaces();
        if(place) { 
            console.log(place.formatted_address)
            console.log(place.geometry.location.lat())
            console.log(place.geometry.location.lng())
        } 
    }

    return (
        <LoadScript googleMapsApiKey="AIzaSyChvsX0nD5MxnWM9bqniO8_Sk9JKLbN51M" libraries={["places"]}>
                <StandaloneSearchBox
                    onLoad={ref => inputRef.current = ref}
                    onPlacesChanged={handlePlaceChanged}
                >
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Location"
                    />
                </StandaloneSearchBox>
        </LoadScript>
    );
};

export default GooglePlacesInput;

import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import './GooglePlacesInput.css';

const GooglePlacesInput = ({ label, onSelect }) => {
  const [showAutocomplete, setShowAutocomplete] = useState(true);
  const [inputValue, setInputValue] = useState('');

  const handleFocus = () => {
    setInputValue('');
    setShowAutocomplete(false);
    setTimeout(() => setShowAutocomplete(true), 0);
  };

  return (
    <div className="places-input-container">
      <label className="places-input-label">{`${label}:`}</label>
      {showAutocomplete && (
        <GooglePlacesAutocomplete
          onSelect={(value, details) => { // Include details in the callback
            console.log("Selected value:", value, "Details:", details);
            setInputValue(value.description);
            onSelect(value, details); // Pass both value and details to the parent component
          }}
          fetchDetails={true} // Set to true to get additional details
          inputStyle={{
            backgroundColor: '#c9f4e4 !important',
            color: 'black',
            width: '100%',
            height: '38.5px',
            padding: '10px',
            textAlign: 'center',
            border: 'none',
            borderRadius: '4px',
            zIndex: 1
          }}
          inputProps={{
            value: inputValue,
            onFocus: handleFocus,
            onBlur: (e) => (e.target.style.backgroundColor = '#c9f4e4')
          }}
        />
      )}
    </div>
  );
};

export default GooglePlacesInput;
*/