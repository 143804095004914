import React, { useState ,useRef, useEffect } from 'react';
import './RiskyBehaviourCheckbox.css';
import {riskyBehaviours, riskyBehaviourMapping} from '../constants'



const RiskyBehaviourCheckbox = ({ label, sex,selectedRiskyBehaviours, setSelectedRiskyBehaviours, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // 1. Add a ref

  
  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    // 2. Add event listener to the document
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isOpen) {
        setIsOpen(false); // 3. If click was outside dropdown and dropdown is open, close it
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleCheck = (riskyBehaviour, e) => {
    setSelectedRiskyBehaviours(prevRiskyBehaviours => {
      let newRiskyBehaviours;
      if (prevRiskyBehaviours.includes(riskyBehaviour)) {
        newRiskyBehaviours = prevRiskyBehaviours.filter(prevRiskyBehaviour => prevRiskyBehaviour !== riskyBehaviour);
      } else {
        newRiskyBehaviours = [...prevRiskyBehaviours, riskyBehaviour];
      }

      // Create the interest object to match the schema using the interestMapping directly
      const riskyBehavioursObj = Object.fromEntries(Object.keys(riskyBehaviourMapping).map(key => [key, newRiskyBehaviours.includes(riskyBehaviourMapping[key])]));

      // Call the prop function to handle this change in the parent component
      onChange(riskyBehavioursObj);
      return newRiskyBehaviours;
    });
    if (e.target.type !== "checkbox") {
      e.stopPropagation();
    }
  };

  return (
    <div className="dropdown-input-container" ref={dropdownRef}>
      <label className="dropdown-input-label">{label}</label>
      <div className={`dropdown ${isOpen ? '' : 'closed'}`}>
        <div className="dropdown-button" onClick={toggleDropdown}>
          {isOpen ? (
            "Select all that apply (please note these results are all confidential and only used for recommendation purposes)"
          ) : (
            selectedRiskyBehaviours.length > 0 ? selectedRiskyBehaviours.join('; ') : "Select all that apply"
          )}
          <span className="dropdown-arrow">{isOpen ? '▲' : '▼'}</span>
        </div>
        {isOpen && (
          <ul>
            {riskyBehaviours.map((riskyBehaviour, index) => (
              <li key={index} onClick={(e) => handleCheck(riskyBehaviour, e)}>
                <label onClick={e => e.stopPropagation()}>
                <input className="multi-checkbox"
                    type="checkbox"
                    checked={selectedRiskyBehaviours.includes(riskyBehaviour)}
                    onChange={(e) => handleCheck(riskyBehaviour,e)}
                  />
                  {riskyBehaviour}
                </label>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default RiskyBehaviourCheckbox;
