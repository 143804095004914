import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import './LoginForm.css'
const { apiUrl } = require('../../config');

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(null);
    
    try {
      const response = await axios.post(`${apiUrl}/users/login`, { email, password });
      
      console.log(response.data.token)   
      setUser({
        token: response.data.token,
        email: email,
        id: response.data.id, 
        healthData: response.data.healthData,  // Include health profile in user state
      });
      localStorage.setItem('user', JSON.stringify({
        token: response.data.token,
        email: email,
        id: response.data.id, 
        healthData: response.data.healthData,  // Include health profile in local storage
      }));
      console.log(response.data.token)
      navigate('/dashboard');  // redirect to home page (or wherever you want to take the user upon login)
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <div className="login-form">
      <h2>Clarissa</h2>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
        <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" required />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button type="submit">Login</button>
      </form>
      <div className="signup-section">
        <p>Don't have an account?</p>
        <button onClick={() => navigate('/register')}>Sign Up</button>
      </div>
    </div>
  );
};

export default LoginForm;
