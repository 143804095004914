import React, {useState, useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import UserMenu from '../UserMenu/UserMenu'
import FeatureModal from '../Modals/FeatureModal/FeatureModal';
import './Screenings.css'; 

const Screenings = () => {
  const { userHealthData } = useContext(UserContext); 
  const [featureModalOpen, setFeatureModalOpen] = useState(false);
  const [clickedFeature, setClickedFeature] = useState("");
  
  

  const handleFeatureModalClose = () => {
    setFeatureModalOpen(false);
    setClickedFeature("");
  };

  const handleFeatureClick = (feature) => {
    setClickedFeature(feature);
    setFeatureModalOpen(true);
  };


  const data = userHealthData.screeningAndHealthPromotion;

  const diseaseScreenings = Object.entries(data || {}).reduce((acc, [key, info]) => {
    if (info.screeningRecommendation !== 'No screening required') {
      acc[key] = info;
    }
    return acc;
  }, {});

  return (
    <div>
      <UserMenu 
        userHealthData={userHealthData} 
        handleFeatureClick={handleFeatureClick}
      />
      <FeatureModal 
        open={featureModalOpen} 
        feature={clickedFeature} 
        onClose={handleFeatureModalClose} 
      />
      <div className="screenings-container">
        <div className="screenings-header">
          <div className="screening-card">
            <h3 className="screening-card-title">Screenings</h3>
            <p className="screening-count">{Object.keys(diseaseScreenings).length}</p>
            <p className="screening-comment">
            {Object.keys(diseaseScreenings).length} screenings are currently recommended for your lifestage
            </p>
          </div>
        </div>
        <div className="screenings-details-container">
          <h2 className="screenings-details-header">Screenings Required</h2>
          <div className="screenings-details">
            {Object.entries(diseaseScreenings).map(([screening, info]) => (
              <div key={screening} className="screening-detail">
                <h3 className="screening-title">{info.title}</h3>
                <p className="screening-description">{info.screeningRecommendation}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screenings;



