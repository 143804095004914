import React, { useState ,useRef, useEffect } from 'react';
import './DiseaseHistoryCheckbox.css';
import {diseases} from '../constants.js'



const DiseaseHistoryCheckbox = ({ label, sex,selectedDiseases, setSelectedDiseases, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  let adjustedDiseases = [...diseases];
  if (sex === 'Male') {
    adjustedDiseases = adjustedDiseases.map(disease => disease === 'Breast Cancer' ? 'Breast Cancer (Male Relative Only)' : disease);
  }




  const dropdownRef = useRef(null); // 1. Add a ref

  
  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    // 2. Add event listener to the document
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isOpen) {
        setIsOpen(false); // 3. If click was outside dropdown and dropdown is open, close it
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleCheck = (disease, e) => {
    setSelectedDiseases(prevDiseases => {
      let newDiseases;
      console.log("Previous Diseases:", prevDiseases); // Debugging line
      if (prevDiseases.includes(disease)) {
        newDiseases = prevDiseases.filter(prevDisease => prevDisease !== disease);
      } else {
        newDiseases = [...prevDiseases, disease];
      }
      console.log("New Diseases:", newDiseases); // Debugging line
      onChange(newDiseases);
      return newDiseases;
    });
    if (e.target.type !== "checkbox") {
      e.stopPropagation();
    }
  };

  return (
    <div className="dropdown-input-container" ref={dropdownRef}>
      <label className="dropdown-input-label">{label}</label>
      <div className={`dropdown ${isOpen ? '' : 'closed'}`}>
        <div className="dropdown-button" onClick={toggleDropdown}>
          {isOpen ? (
            "Select all that apply"
          ) : (
            selectedDiseases.length > 0 ? selectedDiseases.join('; ') : "Select all that apply"
          )}
          <span className="dropdown-arrow">{isOpen ? '▲' : '▼'}</span>
        </div>
        {isOpen && (
          <ul>
            {adjustedDiseases.map((disease, index) => (
              <li key={index} onClick={(e) => handleCheck(disease, e)}>
                <label onClick={e => e.stopPropagation()}>
                  <input className="multi-checkbox"
                    type="checkbox"
                    checked={selectedDiseases.includes(disease)}
                    onChange={(e) => handleCheck(disease, e)}
                  />
                  {disease}
                </label>
              </li>
          ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DiseaseHistoryCheckbox;
