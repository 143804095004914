import React, { useState ,useRef, useEffect } from 'react';
import './HealthInterestCheckbox.css';
import {healthInterests, healthInterestMappingAlt} from "../constants"





const HealthInterestCheckbox = ({ selectedInterests, setSelectedInterests, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // 1. Add a ref


  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    // 2. Add event listener to the document
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isOpen) {
        setIsOpen(false); // 3. If click was outside dropdown and dropdown is open, close it
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleCheck = (interest, e) => {
    setSelectedInterests(prevInterests => {
      let newInterests;
      if (prevInterests.includes(interest)) {
        newInterests = prevInterests.filter(prevInterest => prevInterest !== interest);
      } else {
        newInterests = [...prevInterests, interest];
      }
  
      // Create the interest object to match the schema using the healthInterestMapping directly
      const interestsObj = Object.fromEntries(healthInterests.map(hi => [healthInterestMappingAlt[hi], newInterests.includes(hi)]));
  
      // Call the prop function to handle this change in the parent component
      onChange(interestsObj);
      return newInterests;
    });
    if (e.target.type !== "checkbox") {
      e.stopPropagation();
    }
  };
  
  

  return (
    <div className="dropdown-input-container" ref={dropdownRef}>
      <label className="dropdown-input-label">{`Areas of Health Interest:`}</label>
      <div className={`dropdown ${isOpen ? '' : 'closed'}`}>
        <div className="dropdown-button" onClick={toggleDropdown}>
          {isOpen ? (
            "Select all that apply"
          ) : (
            selectedInterests.length > 0 ? selectedInterests.join('; ') : "Select all that apply"
          )}
          <span className="dropdown-arrow">{isOpen ? '▲' : '▼'}</span>
        </div>
        {isOpen && (
          <ul>
            {healthInterests.map((interest, index) => (
              <li key={index} onClick={(e) => handleCheck(interest, e)}>
                <label onClick={e => e.stopPropagation()}>
                  <input className="multi-checkbox"
                    type="checkbox"
                    checked={selectedInterests.includes(interest)}
                    onChange={(e) => handleCheck(interest,e)}
                  />
                  {interest}
                </label>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default HealthInterestCheckbox;
